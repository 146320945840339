






















import { DocumentReference } from '@firebase/firestore-types';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import CampaignInfoPopup from '@campaigns/shared/components/CampaignInfoPopup.vue';
import { Campaign, CampaignTableRow, Nullable } from '@/app/shared/utilities/static-types';
import { SYSTEM_STATUS } from '@/app/shared/utilities/object-factory';
import { AdminRoutes } from '@/app/shared/utilities/routes-names';
import namespace from '@/app/shared/store/namespace';
import { FirebaseAppFirestore } from '@/app/shared/firebase/firebase-app';
import firebaseNames from '@/app/shared/utilities/firebase-names';

@Component({
  components: {
    CampaignInfoPopup,
  },
})
export default class TableActions extends Vue {
  @Prop({ required: true, default: () => ({}) }) public campaignRow!: CampaignTableRow;
  @Prop({ required: true, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;

  public showCampaignMediaPopup(): void {
    this.selectedCampaign = this.campaignRow;
    (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
  }
}
