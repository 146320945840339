

















import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { Campaign, Nullable } from '@/app/shared/utilities/static-types';
import CampaignItemContent from '@userApp/shared/components/CampaignItemContent.vue';
import ClickableListItem from '@userApp/shared/components/ClickableListItem.vue';
import CampaignInfoPopup from '@/app/screens/App/screens/Campaigns/shared/components/CampaignInfoPopup.vue';

@Component({
  components: {
    CampaignItemContent,
    ClickableListItem,
    CampaignInfoPopup,
  },
})
export default class ClickableCampaignItem extends Vue {
  @Prop({ required: true }) public campaign!: Campaign;
  @Prop({ required: false, default: false }) public isCampaignOwner!: boolean;

  public selectedCampaign: Nullable<Campaign> = null;

  public showCampaignMediaPopup(): void {
    this.selectedCampaign = this.campaign;
    (this.$refs.campaignMediaPopup as CampaignInfoPopup).open();
  }
}
